const $ = require("jquery");
import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css';


//smoothscroll
const header = $('.l-header').outerHeight();
$('a[href^="#"]').on('click', function() {
	const speed = 500,
		href = $(this).attr("href"),
		target = $(href == "#" || href == "" ? "html" : href),
		position = target.offset().top - header;
	$("html, body").animate({ scrollTop: position }, speed, "swing");
	return false;
});
$('.map_anchor_btn_link').on('click', function() {
	const anchor_speed = 500,
		anchor_href = $(this).attr("xlink:href"),
		anchor_target = $(anchor_href == "#" || anchor_href == "" ? "html" : anchor_href),
		anchor_position = anchor_target.offset().top - header;
	$("html, body").animate({ scrollTop: anchor_position }, anchor_speed, "swing");
	return false;
});
$(window).on('load', function() {
	let urlHash = location.hash;
	if (urlHash) {
		let position = $(urlHash).offset().top - header;
		$('html, body').animate({ scrollTop: position }, 0);
	}
});

// PC・SP用 振り分け
const mediaQueryList = window.matchMedia("(max-width:768px)");
const listener = (event) => {
	if (event.matches) {
		// PC用（769px以上）を記載
	} else {
		// SP用（768px以下）を記載
	}
};
mediaQueryList.addEventListener("change", listener);
listener(mediaQueryList);

// SP header menu
$(function() {
	let state = false;
	let pos;
	$('.l-header-menu').click(function() {
		$(this).toggleClass('_active_');
		$('.l-gnav').toggleClass('_gnav-show_');
		$('body').toggleClass('_fixed_');
	});
});

// header fix
$(function() {
	$(window).on('load scroll', function() {
		if ($(this).scrollTop() > 0) {
			$('.l-header').addClass('_fixed_');
		} else {
			$('.l-header').removeClass('_fixed_');
		}
	});
});

// pagetop
function PageTopAnime() {
	$(window).on("scroll", function() {
		if ($(this).scrollTop() > 300) {
			$(".c-pagetop").fadeIn("fast");
		} else {
			$(".c-pagetop").fadeOut("fast");
		}
	});
	let scroll = $(window).scrollTop();
	let wH = window.innerHeight;
	let footerPos =  $('.l-footer-lower').offset().top;
	if(scroll+wH >= (footerPos+25)) {
		let pos = (scroll+wH) - footerPos+25
		$('.c-pagetop').css('bottom',pos);
	} else {
		$('.c-pagetop').css('bottom','25px');
	}
}
$(window).scroll(function () {
	PageTopAnime();
});

// accordion
$(function() {
	$('.hoge').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('_active_');
	});
});

// .m-tab
$(function () {
	$(".m-tab-btn__item").on("click", function () {
		const thisElm = $(this),
		thisTabWrap = thisElm.parents(".m-tab"),
		thisTabBtn = thisTabWrap.find(".m-tab-btn__item"),
		thisTabContents = thisTabWrap.find(".m-tab__content"),
		currentClass = "_current_";

		thisTabBtn.removeClass(currentClass);
		thisElm.addClass(currentClass);

		const thisElmIndex = thisTabBtn.index(this);

		thisTabContents.removeClass(currentClass);
		thisTabContents.eq(thisElmIndex).addClass(currentClass);
	});
});

//.c-product-gallery
$(document).ready(function(){
	$('.c-product-gallery-main').slick({
		arrows: false,
		dots: false,
		autoplay: false,
		fade: true,
		speed: 300,
		swipe: false,
        pauseOnFocus: false,
        pauseOnHover: false,
		asNavFor: ".c-product-gallery-thumb",
	});
	$('.c-product-gallery-thumb').slick({
		arrows: true,
		dots: false,
		autoplay: false,
		swipe: false,
		slidesToShow: 3,
		asNavFor: ".c-product-gallery-main",
		focusOnSelect: true,
		prevArrow: '<span class="c-product-gallery-thumb__arrow _prev_ icon-arrow-left"></span>',
		nextArrow: '<span class="c-product-gallery-thumb__arrow _next_ icon-arrow-right"></span>',
	});
});

//snav _current_取得
const href = location.href;
var links = document.querySelectorAll(".c-snav a");
for (var i = 0; i < links.length; i++) {
	if (links[i].href == href) {
		document.querySelectorAll(".c-snav a")[i].classList.add("_current_");
	}
}